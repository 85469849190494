// eslint-disable-next-line import/order -- must be imported first to catch errors
import './instrument';

import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

// eslint-disable-next-line import/order -- don't import components before this line otherwise style precedence will break
import './styles/index.css';

import { LastResortError } from './components/Errors/LastResort/LastResortError';
import { LastResortLayout } from './layouts/LastResort/LastResort';
import { RouterCalculator } from './routers/router_calculator';
import { ProviderCalculator } from './providers/provider_calculator';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Sentry.ErrorBoundary
    fallback={
      <LastResortLayout>
        <LastResortError />
      </LastResortLayout>
    }
  >
    <ProviderCalculator>
      <RouterCalculator />
    </ProviderCalculator>
  </Sentry.ErrorBoundary>,
);
